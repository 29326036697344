jQuery( document ).ready(function($) {



    //Updated by Shmuel on 7/8/2015
    //Send the client Id with new GA Universal . https://jira-webpals.com:8443/browse/WCMS-3717

    if ( typeof __gaTracker !== 'undefined' ){
        // console.log(__gaTracker);
        var clientId;
        __gaTracker( function( tracker ){ //Run when analytics has fully loaded
            clientId = tracker.get('clientId');
            if ( clientId ){
                $.ajax({
                    //url: ajaxurl,
                    url: document.location.origin + '/wp-content/plugins/wcms_frontend/wcms_ajax_handler.php',
                    type: 'POST',
                    data: {
                        'action':'update_client_id',
                        'client_id' : clientId
                    },
                    success:function(data) {
                        // This outputs the result of the ajax request
                        //console.log(data);
                    },
                    error: function(errorThrown){
                        //console.log(errorThrown);
                    }
                });
            }
        } );
    } else if ( typeof ga !== 'undefined' ){
        // console.log(ga);
        var clientId;
        ga( function( tracker ){ //Run when analytics has fully loaded
            clientId = tracker.get('clientId');
            if ( clientId ){
                $.ajax({
                    //url: ajaxurl,
                    url: document.location.origin + '/wp-content/plugins/wcms_frontend/wcms_ajax_handler.php',
                    type: 'POST',
                    data: {
                        'action':'update_client_id',
                        'client_id' : clientId
                    },
                    success:function(data) {
                        // This outputs the result of the ajax request
                        //console.log(data);
                    },
                    error: function(errorThrown){
                        //console.log(errorThrown);
                    }
                });
            }
        } );
    } else {
        /**
         * changed by Yaniv .
         * suppor
         */
        var utma = readcookie("__utma");
        var utmz = readcookie("__utmz");
        //utma = utma.match(/^[\d]+\.([\d]+)/)[1];
        //utmz = utmz.match(/^[\d]+\.([\d]+)/)[1];
        //var clientId = utma+utmz;

        var clientId = '__utma%3D'+utma+'%3B%2B__utmz%3D'+utmz+'%3B';

        if(utma && utmz)
        {
            $.ajax({
                //url: ajaxurl,
                url: document.location.origin + '/wp-content/plugins/wcms_frontend/wcms_ajax_handler.php', //support multi-site for ajax handler url
                type: 'POST',
                data: {
                    'action':'update_client_id',
                    'client_id' : clientId
                },
                success:function(data) {
                    // This outputs the result of the ajax request
                    //console.log(data);
                },
                error: function(errorThrown){
                    //console.log(errorThrown);
                }
            });

        }

    }

});

function readcookie(name) { var re = new RegExp("(?:^| )" + name + "=([^;]*)", "i"); var matches = document.cookie.match(re); return (matches && matches.length == 2) ? matches[1] : null; }
